body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  background-color: red;
  position: absolute;
  width: 100%;
}

.bucketSelect{
  border: 2;
  margin: 10px;
}

/* .center {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
} */

.d-flex {
  display: flex!important;
  height: 100vh;
}

.center {
  margin: 0 auto;
  align-self: center;
}

.label-h {
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fmemail {
  vertical-align:middle;
  text-align:center;
  position: absolute;
  left: 40%;
 }

 .root {
  width: '100%';
  margin-top: 0
  }

  .Modal{
    width: 100px;
  }

  .toastBody {
    width: 10rem;
    font-size: 16px;
    line-height: 20px;
    padding: 10rem;

    } 
 